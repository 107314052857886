import { motion } from 'framer-motion';
import Reveal from '../misc/Reveal';
import useScrollPosition from '../../hooks/useScrollPosition';

const ScrollProgress = ({ data }) => {
  const scrollIndex = useScrollPosition(data);

  return (
    <Reveal delay={3} still>
      <motion.div className="scroll-steps">
        {data.map((_, index) => (
          <div
            className={`scroll-steps-icons ${index === scrollIndex ? 'active' : ''}`}
            key={index}></div>
        ))}
      </motion.div>
    </Reveal>
  );
};
export default ScrollProgress;
