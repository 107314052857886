import { useParams } from 'react-router-dom';
import { useFirebase } from '../../data/FirebaseContext';
import PortfolioMobilePage from '../../components/portfolio/pages/PortfolioMobilePage';
import PortfolioTabletPage from '../../components/portfolio/pages/PortfolioTabletPage';
import PortfolioWebsitePage from '../../components/portfolio/pages/PortfolioWebsitePage';
import PortfolioLogoPage from '../../components/portfolio/pages/PortfolioLogoPage';
import PortfolioCodeSnippetPage from '../../components/portfolio/pages/PortfolioCodeSnippetPage';

const PortfolioDetailPage = () => {
  const { id } = useParams();
  const { db } = useFirebase();
  const work = db?.work;

  if (!work) {
    return;
  }

  window.scrollTo({ top: 0 });

  // Find portfolio item based on url ID that matches ID in JSON
  const portfolioItem = work.find((item) => item.id === id);

  if (!portfolioItem) {
    throw new Error(`Portfolio item with id ${id} not found`);
  }

  const renderPortfolioPage = (type) => {
    switch (type) {
      case 'mobile':
        return <PortfolioMobilePage data={portfolioItem} />;
      case 'tablet':
        return <PortfolioTabletPage data={portfolioItem} />;
      case 'website':
        return <PortfolioWebsitePage data={portfolioItem} />;
      case 'logo':
        return <PortfolioLogoPage data={portfolioItem} />;
      case 'code':
        return <PortfolioCodeSnippetPage data={portfolioItem} />;
      default:
        return <p>Invalid portfolio item type.</p>;
    }
  };

  return renderPortfolioPage(portfolioItem.type);
};
export default PortfolioDetailPage;
