import htmlRenderer from '../../utils/htmlRenderer';
import Reveal from '../misc/Reveal';

const SectionInfo = ({ data, heading }) => {
  const headingTag = `<${heading}>${data.title}</${heading}>`;

  return (
    <div className="section-info">
      <Reveal>{htmlRenderer(headingTag)}</Reveal>
      <Reveal delay={1.25}>
        <p className="subtitle">{data.subtitle}</p>
      </Reveal>
      <Reveal delay={1.5}>
        <p>{htmlRenderer(data.text)}</p>
      </Reveal>
    </div>
  );
};
export default SectionInfo;
