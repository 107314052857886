import Button from '../ui/Button';
import SectionInfo from '../ui/SectionInfo';
import PortfolioFeature from '../portfolio/components/PortfolioFeature';

const Portfolio = ({ item }) => {
  return (
    <section id="portfolio" className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <SectionInfo data={item} heading="h2" />
        </div>
        <div className="col-12 col-md-10 offset-md-1">
          <PortfolioFeature data={item} homepage />
          <Button data={item} delay={2} />
        </div>
      </div>
    </section>
  );
};
export default Portfolio;
