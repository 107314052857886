import { useLocation } from 'react-router-dom';
import HeaderIcon from './HeaderIcon';
import ScrollProgress from '../scroll/ScrollProgress';
import ScrollDown from '../scroll/ScrollDown';
import Logo from './Logo';
import { useFirebase } from '../../data/FirebaseContext';

const Header = () => {
  const { db } = useFirebase();
  const header = db?.header;
  const homepage = db?.homepage;

  const location = useLocation();
  const home = location.pathname === '/';

  // Don't render anything until both header and homepage are loaded
  if (!header || !homepage) {
    return;
  }

  return (
    <>
      {home && (
        <>
          <ScrollProgress data={homepage} />
          <ScrollDown data={homepage} />
        </>
      )}

      <div id="navigation">
        <Logo data={header} location={location} />
        <div className="nav-icons">
          {header?.icons.map((item, index) => (
            <HeaderIcon
              key={item.title}
              item={item}
              data={homepage}
              index={index}
              home={home}
            />
          ))}
        </div>
        <div className="nav-social">
          {header?.social.map((item) => (
            <HeaderIcon key={item.title} item={item} home={home} social />
          ))}
        </div>
      </div>
    </>
  );
};
export default Header;
