import { useFirebase } from '../../data/FirebaseContext';
import Reveal from '../misc/Reveal';

const StackIcons = ({ data, delay }) => {
  const { db } = useFirebase();
  const icons = db?.stack;

  if (!icons || !data.stack) {
    return;
  }

  const stacksToDisplay = icons.filter((item) => data.stack.includes(item.id));

  return (
    <div className="stack-container">
      {stacksToDisplay.map((item, index) => (
        <Reveal key={index} delay={delay + index / 4} still>
          <img
            src={item.image}
            alt={item.alt}
            title={item.alt}
            className="stack-icon"
          />
        </Reveal>
      ))}
    </div>
  );
};
export default StackIcons;
