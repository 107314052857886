import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from '../../../ui/Image';
import { imageSizes } from '../../../../model/imageSizes';
import Reveal from '../../../misc/Reveal';

const PortfolioPageCarousel = ({ data }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="col-12 col-md-10 offset-md-1 gallery-image">
      <Reveal delay={1}>
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          controls={false}
          indicators={data.images.length > 1 ?? false}>
          {data.images.map((image, index) => (
            <Carousel.Item key={index}>
              <Image images={image} sizes={imageSizes.PORTFOLIO_CAROUSEL} />
            </Carousel.Item>
          ))}
        </Carousel>
      </Reveal>
    </div>
  );
};

export default PortfolioPageCarousel;
