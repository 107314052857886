import SectionInfo from '../../ui/SectionInfo';
import Button from '../../ui/Button';
import useBodyClass from '../../../hooks/useBodyClass';
import StackIcons from '../../ui/StackIcons';
import PortfolioPageFeature from '../components/portfolio-page/PortfolioPageFeature';

const PortfolioLogoPage = ({ data }) => {
  useBodyClass('work-logo');

  return (
    <section id="work" className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6">
          <SectionInfo data={data} heading="h1" />
          <StackIcons data={data} delay={2.25} />
          <Button data={data} delay={2} tabLink />
        </div>
        <PortfolioPageFeature data={data} type="logo" />
      </div>
    </section>
  );
};
export default PortfolioLogoPage;
