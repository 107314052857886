import { imageSizes } from '../../../../model/imageSizes';
import Reveal from '../../../misc/Reveal';
import CodeSnippets from '../../../ui/CodeSnippets';
import Image from '../../../ui/Image';
import PortfolioPageCarousel from './PortfolioPageCarousel';

const PortfolioPageGallery = ({ data, type }) => {
  if (type === 'mobile' || type === 'tablet') {
    return (
      <div className="row gallery-container">
        {data.images.map((image, index) => (
          <div
            key={index}
            className={`col-6 ${type === 'mobile' ? 'col-md-3' : 'col-md-4'} gallery-image`}>
            <Reveal delay={index / 4} useScrollDelay>
              <Image
                images={image}
                sizes={imageSizes[`GALLERY_${type.toUpperCase()}`]}
              />
            </Reveal>
          </div>
        ))}
      </div>
    );
  }

  if (type === 'website') {
    return (
      <div className="row gallery-container">
        <PortfolioPageCarousel data={data} />
      </div>
    );
  }

  if (type === 'code') {
    return <CodeSnippets data={data} />;
  }
};
export default PortfolioPageGallery;
