import { useNavigate } from 'react-router-dom';
import useScrollPosition from '../../hooks/useScrollPosition';

const HeaderIcon = ({ item, social, data, index, home }) => {
  const navigate = useNavigate();
  const scrollIndex = useScrollPosition(data);

  const handleClick = () => {
    const scrollToElement = () => {
      document
        .getElementById(data[index].id)
        .scrollIntoView({ behavior: 'smooth' });
    };

    if (social) {
      window.open(item.url, '_blank', 'noopener noreferrer');
    } else if (home) {
      scrollToElement();
    } else {
      navigate('/');
      setTimeout(scrollToElement, 100);
    }
  };

  const classes = `${!social ? 'icons' : 'social-icons'} ${item.title.toLowerCase()} ${home && scrollIndex === index ? 'active' : ''}`;

  return (
    <button type="button" onClick={handleClick} className={classes}>
      <img
        src={item.image}
        alt={`${item.title} Icon`}
        title={`${item.title} Icon`}
        width={item.width}
        height={item.height}
      />
      {!social && <span>{item.title}</span>}
    </button>
  );
};
export default HeaderIcon;
