import { useEffect } from 'react';
import Reveal from '../misc/Reveal';
import Button from './Button';

const CodeSnippets = ({ data }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cpwebassets.codepen.io/assets/embed/ei.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {data.snippets.map((snippets, index) => (
        <div
          className={`row gallery-container ${index % 2 ? 'flex-row-reverse' : ''}`}>
          <div className="col-12 col-md-5 code-copy">
            <Reveal delay={1.25 + index / 4} useScrollDelay>
              <h2>{snippets.title}</h2>
            </Reveal>
            <Reveal delay={2 + index / 4} useScrollDelay>
              <p>{snippets.description}</p>
            </Reveal>
            <Reveal delay={2.5 + index / 4} useScrollDelay>
              <Button data={snippets} tabLink />
            </Reveal>
          </div>
          <div className="col-12 col-md-7">
            <Reveal delay={2.5 + index / 4} useScrollDelay>
              <p
                className="codepen"
                data-height="400"
                data-theme-id="light"
                data-default-tab={snippets.defaultTab}
                data-slug-hash={snippets.hash}
                data-pen-title={snippets.title}
                data-preview="true"
                data-user="rkerswell1"
                style={{
                  height: '400px',
                  boxSizing: 'border-box',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '2px solid',
                  margin: '1em 0',
                  padding: '1em',
                }}>
                <span>
                  See the Pen{' '}
                  <a
                    href={`https://codepen.io/rkerswell1/pen/${snippets.hash}`}>
                    {snippets.title}
                  </a>{' '}
                  by Ryan Kerswell (
                  <a href="https://codepen.io/rkerswell1">@rkerswell1</a>) on{' '}
                  <a href="https://codepen.io">CodePen</a>.
                </span>
              </p>
            </Reveal>
          </div>
        </div>
      ))}
    </>
  );
};
export default CodeSnippets;
