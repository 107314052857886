import { Link } from 'react-router-dom';
import Image from '../../ui/Image';

const PortfolioItem = ({ image, sizes }) => {
  return (
    <Link to={`/portfolio${image.button.url}`} className="item">
      <Image images={image} sizes={sizes} />
      <div className="item-info">
        <p className="title">{image.title}</p>
        <p className="link">{image.button.text}</p>
      </div>
    </Link>
  );
};
export default PortfolioItem;
