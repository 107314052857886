import PortfolioFeature from '../../components/portfolio/components/PortfolioFeature';
import useBodyClass from '../../hooks/useBodyClass';
import { useFirebase } from '../../data/FirebaseContext';

const PortfolioLandingPage = () => {
  useBodyClass('page-portfolio');
  const { db } = useFirebase();
  const landing = db?.landing;

  if (!landing) {
    return;
  }

  window.scrollTo({ top: 0 });

  return (
    <section id="portfolio-landing" className="container-fluid wide">
      <div className="row">
        <div className="col-12">
          <PortfolioFeature data={landing} />
        </div>
      </div>
    </section>
  );
};

export default PortfolioLandingPage;
