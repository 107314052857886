import { useNavigate } from 'react-router-dom';
import Reveal from '../misc/Reveal';
import htmlRenderer from '../../utils/htmlRenderer';

const Button = ({
  data,
  type = 'button',
  delay = 0,
  tabLink,
  htmlRender,
  disabled,
  success,
  children,
}) => {
  const navigate = useNavigate();

  if (!data.button) {
    return;
  }

  const handleClick = () => {
    tabLink
      ? window.open(data.button.url, '_blank', 'noopener noreferrer')
      : navigate(data.button.url);
  };

  return (
    <Reveal delay={delay}>
      <button
        className={`btn ${success ? ' success' : ''}`}
        onClick={handleClick}
        type={type}
        disabled={disabled}>
        {children ||
          (htmlRender ? htmlRenderer(data.button.text) : data.button.text)}
      </button>
    </Reveal>
  );
};

export default Button;
