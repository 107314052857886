import DOMPurify from 'dompurify';

const htmlRenderer = (html) => {
  const cleanHtml = DOMPurify.sanitize(html);
  return (
    <span
      className="html-renderer"
      dangerouslySetInnerHTML={{ __html: cleanHtml }}
    />
  );
};

export default htmlRenderer;
