import { Link } from 'react-router-dom';

const Logo = ({ data, location }) => {
  const handleClick = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Link id="logo" to="/" onClick={handleClick}>
      <img
        className="logo"
        src={data.logo.image}
        alt={data.logo.title}
        title={data.logo.title}
        width={data.logo.width}
        height={data.logo.height}
      />
    </Link>
  );
};
export default Logo;
