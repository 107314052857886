import useBodyClass from '../../../hooks/useBodyClass';
import Button from '../../ui/Button';
import SectionInfo from '../../ui/SectionInfo';
import StackIcons from '../../ui/StackIcons';
import PortfolioPageFeature from '../components/portfolio-page/PortfolioPageFeature';
import PortfolioPageGallery from '../components/portfolio-page/PortfolioPageGallery';

const PortfolioTabletPage = ({ data }) => {
  useBodyClass('work-tablet');

  return (
    <section id="work" className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6">
          <SectionInfo data={data} heading="h1" />
          <StackIcons data={data} delay={2.25} />
          <Button data={data} delay={2} tabLink />
        </div>
        <PortfolioPageFeature data={data} type="tablet" />
      </div>
      <PortfolioPageGallery data={data} type="tablet" />
    </section>
  );
};
export default PortfolioTabletPage;
