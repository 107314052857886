import { useEffect, useRef, useState } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

/**
 * Reveal Component
 *
 * This component wraps its children with an animation that reveals them
 * when they come into the viewport. It supports an initial delay and
 * an optional adjusted delay when the element is revealed via scrolling.
 *
 * Props:
 * - children: The content to be wrapped and revealed.
 * - delay (number): The initial delay before the reveal animation starts (default: 0.25).
 * - still (boolean): If true, the element will not move vertically during the reveal.
 * - useScrollDelay (boolean): If true, adjusts the delay based on scroll behavior.
 */
const Reveal = ({
  children,
  delay = 0.25,
  still = false,
  useScrollDelay = false,
}) => {
  const ref = useRef(null);

  // useInView hook checks if the element is in the viewport
  // - `once: true` ensures the animation runs only once
  // - `amount: 0.1` means the element is considered in view when 10% is visible
  const isInView = useInView(ref, { once: true, amount: 0.1 });
  const mainControls = useAnimation();
  const [scrollRevealDelay, setScrollRevealDelay] = useState(delay);

  /**
   * useEffect for handling scroll-based delay adjustments
   *
   * - If `useScrollDelay` is true, an IntersectionObserver is set up to monitor
   *   the element's visibility and adjust the delay accordingly.
   * - When the element is below the initial viewport (i.e., scrolled into view),
   *   the delay is set to 0.25 seconds.
   * - The observer is cleaned up when the component unmounts or when dependencies change.
   */
  useEffect(() => {
    const elRef = ref.current;

    if (useScrollDelay) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Check if the element is not fully in view and is below the viewport
          if (
            entry.intersectionRatio < 1 &&
            entry.boundingClientRect.top > window.innerHeight
          ) {
            setScrollRevealDelay(0.25);
          }
        },
        {
          threshold: [0.1],
        }
      );

      if (elRef) {
        observer.observe(elRef);
      }

      return () => {
        if (elRef) observer.unobserve(elRef);
      };
    }
  }, [useScrollDelay]);

  /**
   * useEffect for controlling the animation based on the element's visibility
   *
   * - When `isInView` becomes true, start the 'visible' animation.
   * - When `isInView` is false, revert to the 'hidden' state.
   */
  useEffect(() => {
    if (isInView) {
      mainControls.start('visible');
    } else {
      mainControls.start('hidden');
    }
  }, [isInView, mainControls]);

  const variants = {
    hidden: {
      opacity: 0,
      ...(still ? {} : { y: 150 }),
    },
    visible: {
      opacity: 1,
      ...(still ? {} : { y: 0 }),
    },
  };

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={mainControls}
      transition={{
        duration: 0.5,
        delay: scrollRevealDelay,
      }}
      style={still ? { willChange: 'opacity' } : {}}>
      {children}
    </motion.div>
  );
};

export default Reveal;
