import { useScroll, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';

const useScrollPosition = (data) => {
  const { scrollYProgress } = useScroll();

  // Ensure there's at least one section before calculating progress
  const totalSections = data?.length ? data.length : 1;

  // Scale the scrollYProgress to match the number of sections
  const sectionProgress = useTransform(
    scrollYProgress,
    [0, 1],
    [0, totalSections - 1]
  );

  // Local state to track the active section index
  const [sectionIndex, setSectionIndex] = useState(0);

  // Update the section index based on scroll progress
  useEffect(() => {
    sectionProgress.on('change', (latest) => {
      setSectionIndex(Math.round(latest)); // Round to get the closest section
    });
  }, [sectionProgress]);

  return sectionIndex;
};

export default useScrollPosition;
