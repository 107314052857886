import Reveal from '../misc/Reveal';
import Image from '../ui/Image';
import SectionInfo from '../ui/SectionInfo';
import Button from '../ui/Button';
import { imageSizes } from '../../model/imageSizes';

const About = ({ item }) => {
  return (
    <section id="about" className="container-fluid">
      <div className="row">
        <div className="about col-12 col-md-8 offset-md-2">
          <Reveal>
            <Image images={item.images[0]} sizes={imageSizes.ABOUT} />
          </Reveal>
          <SectionInfo data={item} heading="h2" />
          <Button data={item} delay={1.75} tabLink htmlRender />
        </div>
      </div>
    </section>
  );
};
export default About;
