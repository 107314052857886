import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.scss';
import RootLayout from './root/Root';
import PortfolioRootLayout from './root/PortfolioRoot';
import { FirebaseProvider } from './data/FirebaseContext';
import ErrorPage from './pages/Error';
import Homepage from './pages/Home';
import PortfolioLandingPage from './pages/portfolio/PortfolioLanding';
import PortfolioDetailPage from './pages/portfolio/PortfolioDetail';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Homepage /> },
      {
        path: 'portfolio',
        element: <PortfolioRootLayout />,
        children: [
          {
            index: true,
            element: <PortfolioLandingPage />,
          },
          {
            path: ':id',
            id: 'portfolio-detail',
            element: <PortfolioDetailPage />,
          },
        ],
      },
    ],
  },
]);

const App = () => {
  return (
    <FirebaseProvider>
      <RouterProvider router={router} />
    </FirebaseProvider>
  );
};

export default App;
