import { imageSizes } from '../../../../model/imageSizes';
import Reveal from '../../../misc/Reveal';
import Image from '../../../ui/Image';

const PortfolioPageFeature = ({ data, type }) => {
  if (type === 'logo' || type === 'code') {
    return (
      <div className="col-12 col-md-6 feature-image">
        <Reveal delay={0.5}>
          <div className="feature-image-svg">
            <img
              src={data.feature[0].image}
              alt={data.feature[0].alt}
              title={data.feature[0].alt}
            />
          </div>
        </Reveal>
      </div>
    );
  }

  if (type === 'website') {
    return (
      <div className="col-12 col-md-6 feature-image">
        <Reveal delay={0.5}>
          <Image
            images={data.feature[0]}
            sizes={imageSizes[`FEATURE_${type.toUpperCase()}`]}
          />
        </Reveal>
      </div>
    );
  }

  if (type === 'mobile' || type === 'tablet') {
    return (
      <div className="feature-container">
        <div className="feature-image">
          <Reveal delay={0.5}>
            <Image
              key={0}
              images={data.feature[0]}
              sizes={imageSizes[`FEATURE_${type.toUpperCase()}`]}
            />
          </Reveal>
        </div>
        <div className="feature-image">
          <Reveal delay={0.75}>
            <Image
              key={1}
              images={data.feature[1]}
              sizes={imageSizes[`FEATURE_${type.toUpperCase()}`]}
            />
          </Reveal>
          <Reveal delay={1}>
            <Image
              key={2}
              images={data.feature[2]}
              sizes={imageSizes[`FEATURE_${type.toUpperCase()}`]}
            />
          </Reveal>
        </div>
        {type === 'mobile' && (
          <div className="feature-image">
            <Reveal delay={1.25}>
              <Image
                key={3}
                images={data.feature[3]}
                sizes={imageSizes[`FEATURE_${type.toUpperCase()}`]}
              />
            </Reveal>
          </div>
        )}
      </div>
    );
  }
};
export default PortfolioPageFeature;
