import { useRouteError } from 'react-router-dom';
import Header from '../components/header/Header';

const ErrorPage = () => {
  const error = useRouteError();

  const title = '404';
  let message = 'Could not find resource or page';

  if (error.status === 500) {
    message = error.data.message;
  }

  return (
    <>
      <Header />
      <div className="page-error">
        <h1>{error.status || title}</h1>
        <p>{error.data || message}</p>
      </div>
    </>
  );
};
export default ErrorPage;
