import Reveal from '../misc/Reveal';
import SectionInfo from '../ui/SectionInfo';

const Skills = ({ item }) => {
  return (
    <section id="skills" className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <SectionInfo data={item} heading="h2" />
          <div className="skill-icons">
            {item.icons.map((icon, index) => (
              <div key={index} className="skill-icon">
                <Reveal delay={2 + index * 0.05}>
                  <img src={icon.image} alt={icon.alt} title={icon.alt} />
                </Reveal>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Skills;
