import useScrollPosition from '../../hooks/useScrollPosition';
import Reveal from '../misc/Reveal';

const ScrollDown = ({ data }) => {
  const scrollIndex = useScrollPosition(data);
  const scroll = scrollIndex + 1;

  const handleClick = () => {
    document
      .getElementById(data[scroll].id)
      .scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Reveal delay={3} still>
        <img
          onClick={handleClick}
          className={`scroll-down ${scroll < data.length ? '' : 'hidden'}`}
          src={data[0].icon[0].image}
          alt={data[0].icon[0].alt}
        />
      </Reveal>
    </>
  );
};

export default ScrollDown;
