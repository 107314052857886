import htmlRenderer from '../../utils/htmlRenderer';
import Reveal from '../misc/Reveal';

const Intro = ({ item }) => {
  return (
    <section id="intro" className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Reveal delay={0.5}>
            <h1>{htmlRenderer(item.title)}</h1>
          </Reveal>
          <Reveal delay={2}>
            <h2>{item.subtitle}</h2>
          </Reveal>
        </div>
      </div>
    </section>
  );
};
export default Intro;
