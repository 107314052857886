/*
To determine the correct image size for each viewport, use the image size from the next larger viewport:

1400px viewport: Use the image size for the 1600px viewport
1200px viewport: Use the image size for the 1400px viewport
992px viewport: Use the image size for the 1200px viewport
768px viewport: Use the image size for the 992px viewport
576px viewport: Use the image size for the 768px viewport
320px viewport: Use the image size for the 425px viewport
*/

export const imageSizes = {
  ABOUT: [
    { size: 768, width: 250, height: 250 },
    { size: 320, width: 134, height: 134 },
  ],
  PORTFOLIO_HOMEPAGE: [
    { size: 1400, width: 352, height: 264 },
    { size: 1200, width: 296, height: 222 },
    { size: 992, width: 239, height: 179 },
    { size: 768, width: 333, height: 250 },
    { size: 320, width: 250, height: 188 },
  ],
  PORTFOLIO_LANDING: [
    { size: 992, width: 400, height: 300 },
    { size: 768, width: 333, height: 250 },
    { size: 320, width: 250, height: 188 },
  ],
  FEATURE_WEBSITE: [
    { size: 1400, width: 670, height: 418 },
    { size: 1200, width: 540, height: 337 },
    { size: 992, width: 436, height: 272 },
    { size: 768, width: 324, height: 203 },
    { size: 576, width: 546, height: 340 },
    { size: 320, width: 395, height: 246 },
  ],
  PORTFOLIO_CAROUSEL: [
    { size: 1400, width: 1137, height: 709 },
    { size: 1200, width: 920, height: 573 },
    { size: 992, width: 747, height: 465 },
    { size: 768, width: 560, height: 349 },
    { size: 576, width: 546, height: 340 },
    { size: 320, width: 395, height: 246 },
  ],
  FEATURE_MOBILE: [
    { size: 1400, width: 253, height: 449 },
    { size: 1200, width: 181, height: 322 },
    { size: 992, width: 177, height: 313 },
    { size: 768, width: 137, height: 242 },
  ],
  GALLERY_MOBILE: [
    { size: 1400, width: 320, height: 568 },
    { size: 1200, width: 305, height: 541 },
    { size: 992, width: 255, height: 453 },
    { size: 768, width: 203, height: 360 },
    { size: 576, width: 147, height: 261 },
    { size: 320, width: 174, height: 309 },
  ],
  FEATURE_TABLET: [
    { size: 1400, width: 388, height: 518 },
    { size: 1200, width: 290, height: 387 },
    { size: 992, width: 265, height: 354 },
    { size: 768, width: 210, height: 280 },
  ],
  GALLERY_TABLET: [
    { size: 1400, width: 437, height: 583 },
    { size: 1200, width: 417, height: 556 },
    { size: 992, width: 350, height: 467 },
    { size: 768, width: 281, height: 375 },
    { size: 576, width: 206, height: 275 },
    { size: 320, width: 183, height: 243 },
  ],
};
