import { imageSizes } from '../../../model/imageSizes';
import Reveal from '../../misc/Reveal';
import PortfolioItem from './PortfolioItem';

const PortfolioFeature = ({ data, homepage = false }) => {
  const timer = (index) => {
    if (homepage) {
      return 1.75 + index / 4;
    }
    return 0.5 + index / 8;
  };

  const sizes = homepage
    ? imageSizes.PORTFOLIO_HOMEPAGE
    : imageSizes.PORTFOLIO_LANDING;

  return (
    <div className="portfolio row">
      {data.images.map((images, index) => (
        <div key={index} className="portfolio-item">
          <Reveal delay={timer(index)} useScrollDelay={!homepage ?? false}>
            <PortfolioItem image={images} sizes={sizes} />
          </Reveal>
        </div>
      ))}
    </div>
  );
};
export default PortfolioFeature;
