import useBodyClass from '../hooks/useBodyClass';
import {
  Intro,
  About,
  Skills,
  Portfolio,
  Contact,
} from '../components/homepage';
import { useFirebase } from '../data/FirebaseContext';

const Homepage = () => {
  useBodyClass('page-home');
  const { db } = useFirebase();
  const homepage = db?.homepage;

  // Don't render anything until both headerData and homepageSectionsData (for home) are loaded
  if (!homepage) {
    return;
  }

  return (
    <>
      {homepage.map((item) => {
        switch (item.id) {
          case 'intro':
            return <Intro key={item.id} item={item} />;
          case 'about':
            return <About key={item.id} item={item} />;
          case 'skills':
            return <Skills key={item.id} item={item} />;
          case 'portfolio':
            return <Portfolio key={item.id} item={item} />;
          case 'contact':
            return <Contact key={item.id} item={item} />;
          default:
            return null;
        }
      })}
    </>
  );
};
export default Homepage;
