import { useForm, ValidationError } from '@formspree/react';
import Button from '../ui/Button';
import SectionInfo from '../ui/SectionInfo';
import Reveal from '../misc/Reveal';

const Contact = ({ item }) => {
  const [state, handleSubmit, reset] = useForm('xlepnaqd');

  let message = item.button.text;

  if (state.submitting) {
    message = 'Sending...';
  }
  if (state.succeeded) {
    message = 'Message sent!';
    setTimeout(() => {
      reset();
    }, 3000);
  }

  return (
    <section id="contact" className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <SectionInfo data={item} heading="h2" />
          <div className="container-fluid">
            <Reveal delay={1.75}>
              <form className="row" onSubmit={handleSubmit}>
                <input
                  className="col-12 col-sm-8 col-lg-6"
                  type="text"
                  placeholder="Name"
                  id="name"
                  name="name"
                  required
                />
                <input
                  className="col-12 col-sm-8 col-lg-6"
                  placeholder="Email"
                  id="email"
                  type="email"
                  name="email"
                  required
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
                <textarea
                  className="col-12 col-sm-8 col-lg-6"
                  placeholder="Message"
                  id="message"
                  name="message"
                  required
                />
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
                <Button
                  type="submit"
                  data={item}
                  delay={2}
                  disabled={state.submitting}
                  success={state.succeeded}>
                  {message}
                </Button>
              </form>
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Contact;
