/* eslint react/jsx-no-constructed-context-values: 0 */

import React, { createContext, useContext, useEffect, useState } from 'react';
import { getDatabase, onValue, ref } from 'firebase/database';
import { initializeApp } from 'firebase/app';

// Firebase config from your Firebase console
const firebaseConfig = {
  apiKey: 'AIzaSyB8-4MGwu_NH57C-WzpBhrm3fYQ9Yj5iz4',
  authDomain: 'rkdevelopment-cc65f.firebaseapp.com',
  databaseURL:
    'https://rkdevelopment-cc65f-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'rkdevelopment-cc65f',
  storageBucket: 'rkdevelopment-cc65f.appspot.com',
  messagingSenderId: '593495770112',
  appId: '1:593495770112:web:0373e1a30be6f4405204e5',
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

// Create Context
const FirebaseContext = createContext();

// Custom Hook to use the Firebase Context
export const useFirebase = () => useContext(FirebaseContext);

// Helper function to preload images
const preloadImage = (url) => {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.href = url;
  link.as = 'image';
  document.head.appendChild(link);

  return () => document.head.removeChild(link); // Cleanup function
};

// Provider Component
export const FirebaseProvider = ({ children }) => {
  const [db, setdb] = useState({});

  useEffect(() => {
    const dbRef = ref(database, '/'); // Reference to the root of the database

    // Listen for real-time updates from the database
    const unsubscribe = onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      setdb(data); // Update state with the fetched data

      // Preload other important images if necessary
      if (data?.header?.logo) {
        preloadImage(data.header.logo.image);
      }
      if (data?.header?.icons) {
        data.header.icons.forEach((icon) => preloadImage(icon.image));
      }
      if (data?.header?.social) {
        data.header.social.forEach((social) => preloadImage(social.image));
      }
    });

    return () => unsubscribe(); // Clean up the listener when the component unmounts
  }, []);

  return (
    <FirebaseContext.Provider value={{ db }}>
      {children}
    </FirebaseContext.Provider>
  );
};
