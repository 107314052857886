const Image = ({ images, sizes }) => {
  const cdn = 'https://www.ryankerswell.co.uk/cdn-cgi/image';

  // Available sizes = 1660, 1400, 1200, 992, 768, 576, 320

  return (
    <picture>
      {sizes.map((img, index) => (
        <source
          key={index}
          media={`(min-width: ${img.size}px)`}
          srcSet={`${cdn}/width=${img.width},height=${img.height},fit=contain/${images.image} 1x, ${cdn}/width=${img.width},height=${img.height},fit=contain,dpr=2/${images.image} 2x`}
        />
      ))}
      <img
        width="1"
        height="1"
        src={images.image}
        alt={images.alt}
        title={images.alt}
      />
    </picture>
  );
};
export default Image;
